import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable } from '../../components';
import { getBookmarkList } from '../../redux/slices/userHistory';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
  const { t } = useTranslation();
  const { bookmarkList, list } = useSelector((state) => state.userHistory);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'name', label: t('Nom de lecon') },
    { accessor: 'marked_bookmarked_at', label: t('Date'), type: 'date' },
    { accessor: 'subject_name', label: t('Matière') },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    dispatch(getBookmarkList());
  }, []);

  React.useEffect(() => {
    if (bookmarkList) {
      setData(bookmarkList || []);
    }
  }, [bookmarkList]);

  return (
    <Card className="ta-card">
      <CardContent>
        <div className="child-full">
          <ResponsiveTable cols={headers} rows={data} bookmark={'yes'} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
