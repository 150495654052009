import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {ContentTable} from '../..';
import PropTypes from 'prop-types';
import {recGeneralRec} from '../../../utilities/constants';
import Tooltip from '@material-ui/core/Tooltip';

const Index = (props) => {
  const { t } = useTranslation();
  const {
    courseTabs,
    courses,
    subjectSlug,
    chapterSlug,
    lessonSlug,
    firstLesson,
    recordingTabs,
    //courseProgress
    newcourseProgress
  } = props;
  var firstTab = courseTabs && courseTabs[0]
  var recFirstTab = (recordingTabs || []).find((element, index) => index === 0);
  const currentTab = firstLesson?.tabId;
  const tab = (courseTabs || []).filter((tab) => tab?.id == currentTab)[0];
  const name = tab && tab.name && recordingTabs ? recordingTabs[0].name :  tab?.name;
  const isActive = {
    isactive: name ? name :'Cours',
    tab:  tab ? tab : firstTab,
    //tab: recordingTabs? recordingTabs[0] : tab ? tab : firstTab,
    //isTabLevel:recordingTabs ? true : false
  };
  const [isactive, setIsActive] = useState(isActive || {});
  const handleClick = (index, item , isTabLevel) => {
   
    setIsActive({
      isactive: item.name,
      tab: item,
      //isTabLevel,
    });
  };

  return (
      <div className="root">
        <ButtonGroup className="group">
        {(courseTabs || recordingTabs || []).slice()
        .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1))
          .map((tab, index) => (
            <Tooltip title={tab.name} aria-label="add">
              <Button
                  className="title"
                  onClick={() => handleClick(index, tab, 
                    //!!recordingTabs
                    ) }
                    
                  style={
                    isactive.isactive === tab.name || isactive.name === tab.name ?
                        {background: '#2ba7df', color: '#ffffff'} :
                        {}
                  }
              >
                {t(tab.name)}
              </Button>
              </Tooltip>
          ))
        }
        </ButtonGroup>
        <div style={{width: '100%', marginTop: 5}}>

          <ContentTable
              courses={courses}
              subjectSlug={subjectSlug}
              chapterSlug={chapterSlug}
              lessonSlug={lessonSlug}
              tab={isactive ? isactive.tab : firstTab}
              isTabLevel={isactive.isTabLevel}
              //courseProgress={courseProgress}
              newcourseProgress={newcourseProgress}
          />
        </div>
      </div>
  );
};

Index.propTypes = {
  courseTabs: PropTypes.object,
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
};

export default Index;
